<template>

  <span v-if="options.navmode" class="gradient-bg text-white px-3 rounded uppercase text-xs font-bold max-w-fit">
    beta
  </span>

  <button
    v-else
    @click="openModal"
    class="text-center py-1 px-10 rounded-md w-auto beta-label uppercase"
    :class="{
      'gradient-bg text-white shadow-lg font-bold': !props.options.muted,
      'outline outline-1 text-xs w-auto': props.options.muted
    }"
  >
    Beta
  </button>

  <ClientOnly>
    <dialog
      ref="confirmModal"
      id="confirmModal"
      class="modal modal-bottom sm:modal-middle"
    >
  
      <div class="modal-box">
        <form method="dialog">
          <button
            class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
            @click="closeModal"
          >✕</button>
        </form>
        <h3 class="font-bold text-lg">
          Beta Feature
        </h3>
        <p class="py-2">
          This means we're still working on it and there may be some bugs.
        </p>
        <p class="py-2">
          If you run into any issues, please <NuxtLink
            to="/contact"
            class="text-primary"
          >
            contact us
          </NuxtLink> and we'll get it fixed.
        </p>
        <div class="modal-action">
          <label
            for="my-modal-6"
            class="btn btn-primary"
            @click="closeModal"
          >Got it</label>
        </div>
      </div>
  
      <form
        method="dialog"
        class="modal-backdrop"
      >
        <button>close</button>
      </form>
  
    </dialog>



  </ClientOnly>


</template>

<style scoped>



.gradient-bg {
  background-color: #4158D0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  transition: all 0.1s ease;
}


.beta-label:hover,
.beta-label:active,
.beta-label:focus,
.beta-label:target {
  @apply opacity-60 shadow-none
}

</style>



<script setup>

const confirmModal = ref(null);

// props
const props = defineProps({
  options: {
    type: Object,
    required: false,
    default: {
      muted: false,
      navmode: false
    }
  },
})

onMounted(() => {
  console.log("beta tag mounted");
});

const openModal = () => {
  confirmModal.value.showModal()
};

const closeModal = () => {
  confirmModal.value.close()
};

</script>
